import { Flex, Image, Text } from "@chakra-ui/react";
import logoImage from "../../assets/image/logo.png";
import safePumpImage from "../../assets/image/safe-pump.png";
import twitterImage from "../../assets/image/twitter.png";
import discordImage from "../../assets/image/dc.png";
import tgImage from "../../assets/image/tg-logo.png";
import styles from "./Header.module.scss";
import { menuLinks } from "src/common/pageConfig";
import { useIsMobile } from "src/hook/useIsMobile";

export default function Header() {
  const botUrl = process.env.REACT_APP_BOT_URL!;
  const isMobile = useIsMobile();
  return (
    <Flex className={styles.container}>
      <Flex gap={4} alignItems={"center"}>
        <Image src={logoImage} alt="logo" width="50px" height="36px" />
        <Image src={safePumpImage} alt="logo" height="24px" />
      </Flex>

      <Flex alignItems={"center"} gap={8}>
        {/* {!isMobile && (
          <Text cursor={"pointer"} userSelect={"none"} onClick={() => window.open(botUrl, "_blank")}>
            Connect
          </Text>
        )} */}
        <Image
          src={twitterImage}
          alt="logo"
          height="32px"
          cursor={"pointer"}
          userSelect={"none"}
          onClick={() => window.open("https://x.com/safepumpapp", "_blank")}
        />
        <Image
          src={tgImage}
          alt="logo"
          height="32px"
          cursor={"pointer"}
          userSelect={"none"}
          onClick={() => window.open("https://t.me/safepumpAI", "_blank")}
        />
      </Flex>
    </Flex>
  );
}
